@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/swiper-bundle.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Regular.woff') format('woff'),
    url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.woff') format('woff'),
    url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "Poppins-Regular","PingFang SC","Microsoft YaHei";
  line-height: 2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, 12px);
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size,.16rem,(md:.28rem,sm:.34rem,xs:.28rem));
}

.bold{
  font-family: 'Poppins-Bold';
}

.font56{
  line-height: 1;
  @include res(font-size,.56rem,(md:.64rem,sm:.7rem,xs:.56rem));
}
.font45{
  line-height: 1;
  @include res(font-size,.45rem,(md:.54rem,sm:.6rem,xs:.45rem));
}

//主体内容部分宽度
.container {
  @include res(padding-left, 2.6rem,(lg:2rem,md:1.6rem,sm:1rem,xs:.4rem));
  @include res(padding-right, 2.6rem,(lg:2rem,md:1.6rem,sm:1rem,xs:.4rem));
}

.letter50{
  @include res(letter-spacing,.02rem,(xs:.01rem));
}
.letter100{
  @include res(letter-spacing,.06rem,(xs:.04rem));
}

@keyframes progress {
  0%{transform: scaleX(0);}
  100%{transform: scaleX(1);}
}

.swiper-button-next, .swiper-button-prev{
  z-index: 1;
}

.common-more{
  border-radius: 50px;
  border-style: solid;
  border-color: #323232;
  font-family: 'Poppins-Bold';
  align-items: center;
  line-height: 1;
  transition: all .3s;
  @include res(font-size,.16rem,(md:.2rem,sm:.3rem,xs:.24rem));
  @include res(padding-left,.35rem,(md:.45rem,xs:.3rem));
  @include res(padding-right,.3rem,(md:.4rem,xs:.3rem));
  @include res(padding-top,.12rem,(md:.22rem,xs:.18rem));
  @include res(padding-bottom,.12rem,(md:.2rem,xs:.16rem));
  @include res(border-width,.02rem,(md:.04rem,xs:.02rem));
  @include res(display,inline-flex);
  &:hover{
    background-color: $color-main;
    color: #fff;
    border-color: $color-main;
    .iconfont{
      transform: translateX(.05rem);
    }
  }
  .iconfont{
    line-height: 1;
    display: inline-block;
    transition: transform .3s;
    @include res(margin-left,.05rem,(md:.1rem));
    @include res(font-size,.22rem,(md:.28rem,sm:.5rem,xs:.34rem));
  }
}

.haszw-img{
  position: relative;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }
  b,a{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: all .3s;
  }
}

.common-media-tab{
  text-transform: uppercase;
  line-height: 1.2;
  @include res(font-size,.4rem,(md:.5rem,sm:.6rem,xs:.42rem));
  @include res(margin-bottom,.75rem,(xs:.5rem));
  a{
    color: #b2b2b2;
    transition: all .3s;
    display: inline-block;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    @include res(border-bottom-width,.03rem,(md:2px));
    &:not(:last-child){
      @include res(margin-right,.6rem,(xs:.3rem));
    }
    &:hover{
      color: #000;
    }
    &.active{
      color: #000;
      border-bottom-color: $color-main;
    }
  }
}

.news-list{
  align-items: stretch;
  flex-wrap: wrap;
  @include res(justify-content,space-between);
  @include res(display,flex,(xs:block));
  li{
    background-color: $color-bg;
    transition: all .3s;
    @include res(width,calc(33.33% - .45rem),(md:calc(33.33% - .12rem),xs:100%));
    @include res(margin-bottom,.65rem,(md:.2rem,xs:.4rem));
    &:hover{
      background-color: #fff;
      box-shadow: .1rem .1rem .25rem rgba(0,0,0,.1);
      .item-img b{
        transform: scale(1.05);
      }
      .item-text{
        .title{
          color: $color-main;
        }
      }
    }
    .item-img{
      position: relative;
      .date{
        position: absolute;
        color: #fff;
        z-index: 1;
        @include res(bottom,.1rem);
        @include res(left,.35rem);
      }
      .top{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        color: #fff;
        text-transform: uppercase;
        font-family: 'Poppins-Bold';
        background: url(../../assets/images/icon_top_bg1.png) no-repeat top left;
        background-size: contain;
        @include res(width,.54rem,(md:.8rem,xs:1rem));
        @include res(height,.49rem,(md:.8rem,xs:1rem));
        @include res(font-size, .12rem,(md:.2rem));
        span{
          display: block;
          transform: rotate(-45deg) translateX(10%);
        }
      }
    }
    .item-text{
      @include res(padding-left,.35rem);
      @include res(padding-right,.35rem);
      @include res(padding-top,.35rem);
      @include res(padding-bottom,.35rem);
      .title{
        // color: #000;
        // font-family: 'Poppins-Bold';
        font-family: 'Microsoft YaHei';
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.6;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        transition: all .3s;
        @include res(height,.7rem,(md:1rem,sm:1.2rem));
        @include res(margin-bottom,.15rem,(sm:.2rem));
        // @include res(font-size,.22rem,(md:.3rem,sm:.38rem,xs:.36rem));
        @include res(font-size,.20rem,(md:.28rem,sm:.36rem,xs:.34rem));
      }
      .desc{
        color: $color-desc;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        line-height: 1.875;
        @include res(height,1.2rem,(md:2.2rem,sm:2.6rem,xs:2.1rem));
      }
    }
  }
  .placeholder{
    @include res(width,calc(33.33% - .45rem),(md:calc(33.33% - .12rem),xs:100%));
  }
}

.pro-list{
  ul{
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include res(display,flex);
    @include res(width,calc(100% + .2rem));
    li{
      flex-shrink: 0;
      @include res(width,calc(33.33% - .2rem),(sm:calc(50% - .2rem)));
      @include res(margin-right,.2rem);
      @include res(margin-bottom,.6rem);
      &:hover{
        .item-img img{
          transform: translateY(-5px);
        }
        .item-text{
          color: #000;
        }
      }
      .item-img{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        background-color: #f4f4f4;
        box-sizing: border-box;
        background-image: url(../../assets/images/pro_shadow.png);
        background-repeat: no-repeat;
        background-position: center 87%;
        @include res(background-size,60% auto,(xs:80% auto));
        @include res(height,4.9rem);
        @include res(padding-top,.7rem);
        @include res(padding-bottom,.7rem);
        @include res(padding-left,.1rem);
        @include res(padding-right,.1rem);
        img{
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          transition: all .3s;
        }
        .new{
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          color: #fff;
          text-transform: uppercase;
          font-family: 'Poppins-Bold';
          background: url(../../assets/images/icon_top_bg1.png) no-repeat top left;
          background-size: contain;
          @include res(width,.54rem,(md:.8rem,xs:1rem));
          @include res(height,.49rem,(md:.8rem,xs:1rem));
          @include res(font-size, .12rem,(md:.2rem));
          span{
            display: block;
            transform: rotate(-45deg) translateX(10%);
          }
        }
      }
      .item-text{
        text-align: center;
        line-height: 1.2;
        // font-family: 'Poppins-Bold';
        font-family: 'Microsoft YaHei';
        font-weight: bold;
        color: #666666;
        transition: all .3s;
        // @include res(font-size,.3rem,(md:.34rem,sm:.38rem,xs:.32rem));
        @include res(font-size,.28rem,(md:.32rem,sm:.36rem,xs:.30rem));
        @include res(margin-top,.4rem,(xs:.3rem));
        @include res(margin-bottom,.3rem,(xs:.2rem));
      }
      .item-link{
        text-align: center;
      }
    }
  }
}