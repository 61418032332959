@import "../../assets/styles/utils";

header{
  &.initshow{
    .header{
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: fixed;
    position: absolute;
    left: 0;
    z-index: 1000;
    width: 100%;
    transition: all .4s;
    // @include res(top,.5rem,(xs:0));
    @include res(padding-left,.5rem,(xs:2%));
    @include res(padding-right,.5rem,(xs:5%));
    @include res(height,$header-height-base,$header-height-ratio);
    @include res(opacity,0,(md:1));
    @include res(visibility,hidden,(md:visible));
    @include res(pointer-events,none,(md:all));
    &.scroll{
      top: 0;
      background-color: rgba(30,30,30,.8);
      .right{
        .language{
          &:hover{
            .lan-title{
              background-color: #1e1e1e;
            }
          }
          .lan-cont>div{
            background-color: #1e1e1e;
          }
        }
      }
    }
    &.trans{
      transform: translateY(-100%);
    }
    .logo{
      img{
        display: block;
        height: auto;
        @include res(width,3.28rem,(sm:4.5rem,xs:3.2rem));
      }
    }
    .right{
      align-items: center;
      @include res(margin-top,.1rem);
      @include res(display,flex);
      .nav{
        color: #fff;
        text-transform: uppercase;
        @include res(display,block,(sm:none));
        @include res(font-size,.19rem,(md:.24rem));
        a{
          display: inline-block;
          transition: all .3s;
          &:hover{
            color: $color-main;
          }
          &:not(:last-child){
            @include res(margin-right,.5rem,(md:.3rem));
          }
        }
      }
      .language{
        position: relative;
        text-transform: uppercase;
        @include res(font-size,.14rem,(md:.26rem,sm:.36rem,xs:.28rem));
        @include res(margin-left,.35rem,(sm:0));
        @include res(margin-right,0,(sm:.35rem));
        &:hover{
          .lan-title{
            border-bottom-color: transparent;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
          .lan-cont{
            visibility: visible;
            >div{
              transform: translateY(0%);
            }
          }
        }
        .lan-title{
          border: solid #fff;
          color: #fff;
          border-radius: .06rem;
          transition: all .3s;
          cursor: pointer;
          @include res(padding-left,.2rem);
          @include res(padding-right,.12rem);
          @include res(border-width,.01rem,(md:1px));
        }
        .lan-cont{
          position: absolute;
          top: calc(100% - 1px);
          left: 0;
          width: 100%;
          overflow: hidden;
          visibility: hidden;
          >div{
            width: 100%;
            border: solid #fff;
            border-radius: .06rem;
            border-top: none;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            transition: all .3s;
            transform: translateY(-100%);
            overflow: hidden;
            @include res(border-width,.01rem,(md:1px));
          }
          a{
            display: block;
            color: #fff;
            transition: all .3s;
            @include res(padding-left,.2rem);
            &:hover{
              background-color: #fff;
              color: #000;
            }
          }
        }
      }
      .hamburger{
        @include res(display,none,(sm:block));
      }
    }
  }
  .mob-header{
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 998;
    height: 100vh;
    overflow-x: hidden;
    background: #1e1e1e;
    background-size: cover;
    transition: all $anime-duration;
    @include res(padding-top, 3rem,(xs:1.3rem));
    @include res(padding-bottom, 1.5rem,(xs:.5rem));
    @include res(padding-left, 1rem,(xs:.5rem));
    @include res(padding-right, 1rem,(xs:.5rem));
    @include res(width,60%,(sm:80%,xs:100%));
    &.show{
      transform: translateX(-100%);
    }
    ul{
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      li{
        color: #fff;
        border-bottom: 1px solid rgba(255,255,255,.1);
        letter-spacing: .01rem;
        &.active{
          .title .iconfont{
            transform: rotate(90deg);
          }
        }
        .title{
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          @include res(line-height,1.4rem,(xs:1rem));
          @include res(padding-left,.3rem);
          @include res(display,flex);
          @include res(font-size,.48rem,(xs:.36rem));
          a{
            flex-grow: 1;
          }
          .iconfont{
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            transform-origin: center;
            @include res(display,none);
            @include res(font-size,.4rem,(xs:.34rem));
            @include res(width,1.4rem,(xs:1rem));
            @include res(height,1.4rem,(xs:1rem));
          }
        }
        .sub-nav{
          display: none;
          @include res(padding-left, .6rem);
          @include res(padding-right, .6rem);
          @include res(padding-bottom,.3rem);
          a{
            display: block;
          }
        }
      }
    }
  }
  .menu-mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 997;
    display: none;
  }
  &.es-header{
    .header{
      .right .nav a{
        &:not(:last-child){
          @include res(margin-right,.3rem,(md:.3rem));
        }
      }
    }
  }
}