@import "../../assets/styles/utils";
@import "../../assets/styles/share.min.css";

footer{
  background-color: #1e1e1e;
  color: rgba(255,255,255,.6);
  position: relative;
  @include res(margin-top,1.25rem,(xs:.8rem));
  @include res(padding-left,.85rem,(xs:.3rem));
  @include res(padding-right,.85rem,(xs:.3rem));
  @include res(padding-top,.85rem);
  @include res(padding-bottom,.4rem);
  a{
    transition: color .3s;
    &:hover{
      color: rgba(255,255,255,1);
    }
  }
  .footer{
    align-items: flex-start;
    justify-content: space-between;
    @include res(display,flex,(xs:block));
    .left{
      @include res(max-width,3.6rem,(md:5.6rem,xs:100%));
      .logo{
        @include res(margin-bottom,.2rem,(md:.4rem));
        img{
          display: block;
          height: auto;
          @include res(margin,null,(xs:0 auto));
          @include res(width,2.9rem,(md:4.9rem));
        }
      }
      .info{
        color: rgba(255,255,255,1);
        .info-title{
          @include res(text-align,left,(xs:center));
          @include res(margin-bottom,.15rem);
          @include res(font-size,.26rem,(md:.4rem));
          &::before{
            content: '';
            display: block;
            background-color: #fff;
            @include res(margin,null,(xs:0 auto));
            @include res(width,.33rem);
            @include res(height,.04rem);
            @include res(margin-bottom,.1rem,(md:.3rem));
          }
        }
        .info-cont{
          @include res(text-align,left,(xs:center));
          @include res(font-size,.18rem,(md:.28rem));
          >div{
            @include res(margin-bottom,.25rem);
          }
        }
      }
    }
    .right{
      ul{
        align-items: flex-start;
        justify-content: flex-end;
        @include res(display,flex,(xs:block));
        li{
          @include res(border-bottom,none,(xs:1px solid rgba(255,255,255,.1)));
          &:not(:first-child){
            @include res(margin-left,.6rem,(md:1.2rem,sm:.8rem,xs:0));
          }
          &:not(:last-child){
            @include res(width,2.6rem,(md:auto));
          }
          &.active{
            .nav-title{
              .iconfont{
                transform: rotate(90deg);
              }
            }
          }
          .nav-title{
            color: #fff;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include res(margin-bottom,.15rem,(xs:0));
            @include res(font-size,.22rem,(md:.34rem));
            a{
              flex-grow: 1;
              line-height: 1.2;
              @include res(margin-left,0,(xs:.1rem));
              @include res(padding-top,.1rem,(xs:.1rem));
              @include res(padding-bottom,.1rem,(xs:.1rem));
            }
            .iconfont{
              transition: all .3s;
              flex-shrink: 0;
              align-items: center;
              justify-content: center;
              @include res(display,none,(xs:inline-flex));
              @include res(width,.6rem);
            }
          }
          .nav-link{
            @include res(display,block,(xs:none));
            @include res(font-size,.18rem,(md:.26rem,sm:.28rem));
            a{
              display: block;
              line-height: 1.2;
              @include res(padding-left,0,(xs:.3rem));
              @include res(padding-top,.07rem,(xs:.1rem));
              @include res(padding-bottom,.07rem,(xs:.1rem));
            }
          }
        }
      }
    }
  }
  .footer-btm{
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    @include res(font-size,null,(md:.26rem,sm:.28rem,xs:.24rem));
    @include res(margin-top,.65rem,(xs:.1rem));
    @include res(display,flex,(md:block));
    .friend-link{
      position: relative;
      @include res(margin-bottom,0,(xs:.1rem));
      .friend-link-title{
        align-items: center;
        justify-content: space-between;
        padding-left: .1rem;
        @include res(display,none,(xs:flex));
        .iconfont{
          transition: all .3s;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          @include res(display,none,(xs:inline-flex));
          @include res(width,.6rem);
        }
        &.active{
          .iconfont{
            transform: rotate(90deg);
          }
        }
      }
      .friend-link-cont{
        @include res(display,null,(xs:none));
        a{
          @include res(width,auto,(xs:49%));
          @include res(padding,0,(xs:.1rem));
          @include res(display,null,(xs:inline-block));
          &:not(:last-child){
            @include res(margin-right,.1rem,(xs:0));
          }
        }
      }
    }
    .copyright{
      @include res(line-height,null,(xs:1.2));
    }
  }
  .aside-icon{
    position: fixed;
    z-index: 7;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s;
    @include res(bottom,100px);
    @include res(right,.5rem,(xs:.2rem));
    &.show{
      opacity: 1;
      visibility: visible;
    }
    &.abs{
      position: absolute;
      @include res(bottom, calc(100% + 10px));
    }
    .share{
      position: relative;
      &:hover{
        .share-icon{
          background-color: $color-main;
        }
        .social-share{
          .social-share-icon{
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        }
      }
      .share-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgba(0,0,0,.3);
        color: #fff;
        cursor: pointer;
        transition: all .3s;
        @include res(width,.65rem,(md:.8rem));
        @include res(height,.65rem,(md:.8rem));
        .iconfont{
          @include res(font-size,.26rem,(md:.36rem));
        }
      }
      .social-share{
        position: absolute;
        left: 0;
        display: flex;
        flex-direction: column;
        @include res(bottom,100%);
        .social-share-icon{
          border: none;
          background-color: rgba(0,0,0,.3);
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          cursor: pointer;
          transition: all .3s;
          opacity: 0;
          visibility: hidden;
          transform: translateY(.05rem);
          @include res(margin-bottom,.1rem);
          @include res(font-size,.32rem,(md:.42rem));
          @include res(width,.65rem,(md:.8rem));
          @include res(height,.65rem,(md:.8rem));
          &:hover{
            background-color: $color-main-dark;
          }
          &:nth-child(1){
            transition-delay: .08s;
          }
        }
      }
    }
    .back-top{
      border-radius: 50%;
      background-color: rgba(0,0,0,.3);
      color: #fff;
      cursor: pointer;
      transition: all .3s;
      display: flex;
      align-items: center;
      justify-content: center;
      @include res(width,.65rem,(md:.8rem));
      @include res(height,.65rem,(md:.8rem));
      @include res(margin-top,.1rem);
      .iconfont{
        @include res(font-size,.26rem,(md:.36rem));
      }
      &:hover{
        background-color: $color-main;
      }
    }
  }
}
.cookie-consent{
  border: 1px solid #abe0f8;
  background: #FFF;
  padding: .3rem .3rem .25rem .3rem;
  max-width: 430px;
  width: calc(100% - 20px);
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 500;
  border-radius: .2rem;
  &.show{
    display: block;
  }
  &.hide{
    display: none;
  }
  p{
    @include res(font-size,.16rem,(xs:14px));
    margin-bottom: .2rem;
    line-height: 1.5;
    a{
      color: #00a0e8;
      text-decoration: underline;
    }
  }
  .btn-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a.button{
      color: #fff;
      padding: .08rem .2rem;
      background-color: #00a0e8;
      border-radius: .3rem;
      transition: all 0.3s ease-out;
      @include res(font-size,.16rem,(xs:14px));
      &.reject{
        margin-right: .3rem;
      }
      &:hover{
        background-color: #0877aa;
      }
    }
  }
}