@import "./utils";

body {
  text-align: right;
}
header .header .right .language .lan-title{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
header .header .right .language .lan-cont a{
  @include res(padding-left,0rem);
  @include res(padding-right,.2rem);
}
header .mob-header ul li .title{
  flex-direction: row-reverse;
  padding-left: 0;
  @include res(padding-right,.3rem);
}
header .mob-header ul li .title .iconfont::before{
  transform: rotate(180deg);
}

footer .footer .right ul li .nav-title{
  @include res(flex-direction,null,(xs:row-reverse));
}
footer .footer .right ul li .nav-title a{
  margin-left: 0;
  @include res(margin-right,0,(xs:.1rem));
}
footer .footer .right ul li .nav-title .iconfont::before{
  transform: rotate(180deg);
}
footer .footer .right ul li .nav-link a{
  padding-left: 0;
  @include res(padding-right,0,(xs:.3rem));
}
footer .footer-btm .friend-link .friend-link-title{
  flex-direction: row-reverse;
  padding-right: .1rem;
  padding-left: 0;
}
footer .footer-btm .friend-link .friend-link-title .iconfont::before{
  transform: rotate(180deg);
}

.common-more{
  flex-direction: row-reverse;
}
.common-more .iconfont{
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: .05rem;
}
.common-more:hover .iconfont{
  transform: translateX(-.05rem) rotate(180deg);
}
.news-list li .item-img .date{
  left: auto;
  @include res(right,.35rem);
}
.crumbs{
  flex-direction: row-reverse;
}
.crumbs .iconfont{
  display: block;
  transform: rotate(180deg);
}
#page ul, .pages{
  flex-direction: row-reverse;
}
#page ul a .iconfont, #page ul li .iconfont, .pages a .iconfont, .pages li .iconfont{
  display: block;
  transform: rotate(180deg);
}

.pro-list ul li .item-img .new span{
  text-align: left;
}

.page-index{
  .index-banner .swiper .swiper-slide .banner-t h1:before{
    position: absolute;
    bottom: calc(100% + .25rem);
    right: 0;
  }
  .index-banner .swiper .swiper-contral{
    right: auto;
    @include res(left,2.6rem,(lg:2rem,md:1.6rem,sm:1rem,xs:.4rem));
  }
  .index-banner .swiper .swiper-contral .swiper-mypagination{
    text-align: left;
  }
}


.page-about{
  .section1 .cont1 .text .title{
    @include res(text-align,right,(xs:center));
  }
  .section1 .num-cont .num .item .top{
    flex-direction: row-reverse;
  }
  .section1 .num-cont .num .item .top .number{
    flex-direction: row-reverse;
    margin-right: 0;
    @include res(margin-left,.1rem);
  }
  .section1 .cont2 .text .title{
    @include res(text-align,right,(xs:center));
  }
  .section4 ul li .text .title{
    left: auto;
    @include res(right,50%);
    @include res(transform,translateX(50%));
  }
  .section4 ul li.active .text .title{
    left: auto;
    @include res(right,.6rem,(md:50%));
    @include res(transform,translateX(0%),(md:translateX(50%)));
  }
}

.page-article-pro{
  .section1 .right .text .t-title{
    @include res(text-align,right,(sm:center));
  }
  .section2 ul li .item-text .title{
    @include res(text-align,right,(xs:center));
  }
  .section3 .size-tab{
    flex-direction: row-reverse;
  }
  .section3 .size-tab>div:not(:last-child){
    margin-right: 0;
    @include res(margin-left,.45rem);
  }
}

.page-contact{
  .section1 .left .info .item{
    flex-direction: row-reverse;
  }
  .section1 .left .info .item .iconfont{
    margin-right: 0;
    @include res(margin-left,.1rem,(sm:.2rem));
  }
  .section1 .right .form form .form-item input, .section1 .right .form form .form-item textarea{
    text-align: right;
  }
}

.page-list-pro{
  .product .pro-nav-cont .nav-search{
    flex-direction: row-reverse;
  }
  .product .pro-nav-cont .nav-search input{
    text-align: right;
    padding-left: 0;
    @include res(padding-right,.25rem);
  }
  .product .pro-nav-cont .pro-nav li .pro-nav-title{
    flex-direction: row-reverse;
    padding-left: 0;
    @include res(padding-right,.25rem);
  }
}

.page-why{
  .fixed-sub-nav .cont{
    flex-direction: row-reverse;
  }
  .fixed-sub-nav .cont a{
    flex-direction: row-reverse;
  }
  .fixed-sub-nav .cont a span:first-child{
    margin-right: 0;
    @include res(margin-left,.2rem);
  }
  .why .cont{
    padding-left: 0;
    @include res(padding-right,1.4rem,(md:0));
  }
  .why .cont .title1{
    padding-left: 0;
    @include res(padding-right,0,(md:1.4rem,xs:1rem));
  }
  .why .cont .title1 span:first-child{
    left: auto;
    @include res(right,-1.4rem,(md:0));
  }
}