@import "../../assets/styles/utils";

.pages,#page ul{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @include res(margin-top,.5rem,(md:.8rem));
  a,li{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f2f2f3;
    transition: $anime-duration $anime-bezier;
    cursor: pointer;
    transition: all .4s;
    @include res(margin-bottom,.04rem);
    @include res(margin-left,.04rem);
    @include res(margin-right,.04rem);
    @include res(font-size,.18rem,(md:.28rem,sm:.34rem,xs:.24rem));
    @include res(width, .46rem,(md:.7rem,sm:1rem,xs:.7rem));
    @include res(height, .46rem,(md:.7rem,sm:1rem,xs:.7rem));
    .iconfont{
      @include res(font-size, .2rem,(md:.34rem,sm:.44rem,xs:.34rem));
    }
    &:hover{
      background-color: #ebebeb;
      color: $color-main;
    }
    &.active,&.xl-active{
      background-color: $color-main;
      color: #fff;
    }
    &.xl-prevPage,&.xl-nextPage{
      display: none;
    }
  }
  .page_jump_text{
    @include res(margin-left,10px);
    @include res(margin-right,10px);
  }
  .page_jump_input{
    @include res(width,60px, 60 / 60);
    @include res(height,30px,30 / 30);
  }
  .page_jump_btn{
    background: none;
    border: none;
    cursor: pointer;
    transition: all;
    &:hover{
      color: $color-main;
    }
  }
}