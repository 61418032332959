@font-face {
	font-family:"socialshare";
	src:url("../fonts/iconfont_share.eot");
    src:url("../fonts/iconfont_share.eot?#iefix") format("embedded-opentype"),
    url("../fonts/iconfont_share.woff") format("woff"),
    url("../fonts/iconfont_share.ttf") format("truetype"),
    url("../fonts/iconfont_share.svg#iconfont") format("svg")
}
.social-share {
	font-family:"socialshare" !important;
	font-size:16px;
	font-style:normal;
	-webkit-font-smoothing:antialiased;
	-webkit-text-stroke-width:0.2px;
	-moz-osx-font-smoothing:grayscale
}
.social-share * {
	font-family:"socialshare" !important
}
.social-share .icon-tencent:before {
	content:"\f07a"
}
.social-share .icon-qq:before {
	content:"\f11a"
}
.social-share .icon-weibo:before {
	content:"\f12a"
}
.social-share .icon-wechat:before {
	content:"\f09a"
}
.social-share .icon-douban:before {
	content:"\f10a"
}
.social-share .icon-heart:before {
	content:"\f20a"
}
.social-share .icon-like:before {
	content:"\f00a"
}
.social-share .icon-qzone:before {
	content:"\f08a"
}
.social-share .icon-linkedin:before {
	content:"\f01a"
}
.social-share .icon-diandian:before {
	content:"\f05a"
}
.social-share .icon-facebook:before {
	content:"\f03a"
}
.social-share .icon-google:before {
	content:"\f04a"
}
.social-share .icon-twitter:before {
	content:"\f06a"
}
.social-share a {
	position:relative;
	text-decoration:none;
	margin:4px;
	display:inline-block;
	outline:none
}
.social-share .social-share-icon {
	position:relative;
	display:inline-block;
	width:32px;
	height:32px;
	font-size:20px;
	border-radius:50%;
	line-height:32px;
	border:1px solid #666;
	color:#666;
	text-align:center;
	vertical-align:middle;
	transition:background 0.6s ease-out 0s
}
.social-share .social-share-icon:hover {
	background:#666;
	color:#fff
}
.social-share .icon-weibo {
	color:#ff763b;
	border-color:#ff763b
}
.social-share .icon-weibo:hover {
	background:#ff763b
}
.social-share .icon-tencent {
	color:#56b6e7;
	border-color:#56b6e7
}
.social-share .icon-tencent:hover {
	background:#56b6e7
}
.social-share .icon-qq {
	color:#56b6e7;
	border-color:#56b6e7
}
.social-share .icon-qq:hover {
	background:#56b6e7
}
.social-share .icon-qzone {
	color:#FDBE3D;
	border-color:#FDBE3D
}
.social-share .icon-qzone:hover {
	background:#FDBE3D
}
.social-share .icon-douban {
	color:#33b045;
	border-color:#33b045
}
.social-share .icon-douban:hover {
	background:#33b045
}
.social-share .icon-linkedin {
	color:#0077B5;
	border-color:#0077B5
}
.social-share .icon-linkedin:hover {
	background:#0077B5
}
.social-share .icon-facebook {
	color:#44619D;
	border-color:#44619D
}
.social-share .icon-facebook:hover {
	background:#44619D
}
.social-share .icon-google {
	color:#db4437;
	border-color:#db4437
}
.social-share .icon-google:hover {
	background:#db4437
}
.social-share .icon-twitter {
	color:#55acee;
	border-color:#55acee
}
.social-share .icon-twitter:hover {
	background:#55acee
}
.social-share .icon-diandian {
	color:#307DCA;
	border-color:#307DCA
}
.social-share .icon-diandian:hover {
	background:#307DCA
}
.social-share .icon-wechat {
	position:relative;
	color:#7bc549;
	border-color:#7bc549
}
.social-share .icon-wechat:hover {
	background:#7bc549
}
.social-share .icon-wechat .wechat-qrcode {
	display:none;
	border:1px solid #eee;
	position:absolute;
	z-index:9;
	top:-205px;
	left:-84px;
	width:200px;
	height:192px;
	color:#666;
	font-size:12px;
	text-align:center;
	background-color:#fff;
	box-shadow:0 2px 10px #aaa;
	transition:all 200ms;
	-webkit-tansition:all 350ms;
	-moz-transition:all 350ms
}
.social-share .icon-wechat .wechat-qrcode.bottom {
	top:40px;
	left:-84px
}
.social-share .icon-wechat .wechat-qrcode.bottom:after {
	display:none
}
.social-share .icon-wechat .wechat-qrcode h4 {
	font-weight:normal;
	height:26px;
	line-height:26px;
	font-size:12px;
	background-color:#f3f3f3;
	margin:0;
	padding:0;
	color:#777
}
.social-share .icon-wechat .wechat-qrcode .qrcode {
	width:105px;
	margin:10px auto
}
.social-share .icon-wechat .wechat-qrcode .qrcode table {
	margin:0 !important
}
.social-share .icon-wechat .wechat-qrcode .help p {
	font-weight:normal;
	line-height:16px;
	padding:0;
	margin:0
}
.social-share .icon-wechat .wechat-qrcode:after {
	content:'';
	position:absolute;
	left:50%;
	margin-left:-6px;
	bottom:-13px;
	width:0;
	height:0;
	border-width:8px 6px 6px 6px;
	border-style:solid;
	border-color:#fff transparent transparent transparent
}
.social-share .icon-wechat:hover .wechat-qrcode {
	display:block
}
