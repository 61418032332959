body {
  text-align: right;
}

header .header .right .language .lan-title {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

header .header .right .language .lan-cont a {
  padding-left: 0rem;
  padding-right: 0.2rem;
}

header .mob-header ul li .title {
  flex-direction: row-reverse;
  padding-left: 0;
  padding-right: 0.3rem;
}

header .mob-header ul li .title .iconfont::before {
  transform: rotate(180deg);
}

@media (max-width: 767px) {
  footer .footer .right ul li .nav-title {
    flex-direction: row-reverse;
  }
}

footer .footer .right ul li .nav-title a {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 767px) {
  footer .footer .right ul li .nav-title a {
    margin-right: 0.1rem;
  }
}

footer .footer .right ul li .nav-title .iconfont::before {
  transform: rotate(180deg);
}

footer .footer .right ul li .nav-link a {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 767px) {
  footer .footer .right ul li .nav-link a {
    padding-right: 0.3rem;
  }
}

footer .footer-btm .friend-link .friend-link-title {
  flex-direction: row-reverse;
  padding-right: .1rem;
  padding-left: 0;
}

footer .footer-btm .friend-link .friend-link-title .iconfont::before {
  transform: rotate(180deg);
}

.common-more {
  flex-direction: row-reverse;
}

.common-more .iconfont {
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: .05rem;
}

.common-more:hover .iconfont {
  transform: translateX(-0.05rem) rotate(180deg);
}

.news-list li .item-img .date {
  left: auto;
  right: 0.35rem;
}

.crumbs {
  flex-direction: row-reverse;
}

.crumbs .iconfont {
  display: block;
  transform: rotate(180deg);
}

#page ul, .pages {
  flex-direction: row-reverse;
}

#page ul a .iconfont, #page ul li .iconfont, .pages a .iconfont, .pages li .iconfont {
  display: block;
  transform: rotate(180deg);
}

.pro-list ul li .item-img .new span {
  text-align: left;
}

.page-index .index-banner .swiper .swiper-slide .banner-t h1:before {
  position: absolute;
  bottom: calc(100% + .25rem);
  right: 0;
}

.page-index .index-banner .swiper .swiper-contral {
  right: auto;
  left: 2.6rem;
}

@media (max-width: 1600px) {
  .page-index .index-banner .swiper .swiper-contral {
    left: 2rem;
  }
}

@media (max-width: 1024px) {
  .page-index .index-banner .swiper .swiper-contral {
    left: 1.6rem;
  }
}

@media (max-width: 991px) {
  .page-index .index-banner .swiper .swiper-contral {
    left: 1rem;
  }
}

@media (max-width: 767px) {
  .page-index .index-banner .swiper .swiper-contral {
    left: 0.4rem;
  }
}

.page-index .index-banner .swiper .swiper-contral .swiper-mypagination {
  text-align: left;
}

.page-about .section1 .cont1 .text .title {
  text-align: right;
}

@media (max-width: 767px) {
  .page-about .section1 .cont1 .text .title {
    text-align: center;
  }
}

.page-about .section1 .num-cont .num .item .top {
  flex-direction: row-reverse;
}

.page-about .section1 .num-cont .num .item .top .number {
  flex-direction: row-reverse;
  margin-right: 0;
  margin-left: 0.1rem;
}

.page-about .section1 .cont2 .text .title {
  text-align: right;
}

@media (max-width: 767px) {
  .page-about .section1 .cont2 .text .title {
    text-align: center;
  }
}

.page-about .section4 ul li .text .title {
  left: auto;
  right: 50%;
  transform: translateX(50%);
}

.page-about .section4 ul li.active .text .title {
  left: auto;
  right: 0.6rem;
  transform: translateX(0%);
}

@media (max-width: 1024px) {
  .page-about .section4 ul li.active .text .title {
    right: 50%;
  }
}

@media (max-width: 1024px) {
  .page-about .section4 ul li.active .text .title {
    transform: translateX(50%);
  }
}

.page-article-pro .section1 .right .text .t-title {
  text-align: right;
}

@media (max-width: 991px) {
  .page-article-pro .section1 .right .text .t-title {
    text-align: center;
  }
}

.page-article-pro .section2 ul li .item-text .title {
  text-align: right;
}

@media (max-width: 767px) {
  .page-article-pro .section2 ul li .item-text .title {
    text-align: center;
  }
}

.page-article-pro .section3 .size-tab {
  flex-direction: row-reverse;
}

.page-article-pro .section3 .size-tab > div:not(:last-child) {
  margin-right: 0;
  margin-left: 0.45rem;
}

.page-contact .section1 .left .info .item {
  flex-direction: row-reverse;
}

.page-contact .section1 .left .info .item .iconfont {
  margin-right: 0;
  margin-left: 0.1rem;
}

@media (max-width: 991px) {
  .page-contact .section1 .left .info .item .iconfont {
    margin-left: 0.2rem;
  }
}

.page-contact .section1 .right .form form .form-item input, .page-contact .section1 .right .form form .form-item textarea {
  text-align: right;
}

.page-list-pro .product .pro-nav-cont .nav-search {
  flex-direction: row-reverse;
}

.page-list-pro .product .pro-nav-cont .nav-search input {
  text-align: right;
  padding-left: 0;
  padding-right: 0.25rem;
}

.page-list-pro .product .pro-nav-cont .pro-nav li .pro-nav-title {
  flex-direction: row-reverse;
  padding-left: 0;
  padding-right: 0.25rem;
}

.page-why .fixed-sub-nav .cont {
  flex-direction: row-reverse;
}

.page-why .fixed-sub-nav .cont a {
  flex-direction: row-reverse;
}

.page-why .fixed-sub-nav .cont a span:first-child {
  margin-right: 0;
  margin-left: 0.2rem;
}

.page-why .why .cont {
  padding-left: 0;
  padding-right: 1.4rem;
}

@media (max-width: 1024px) {
  .page-why .why .cont {
    padding-right: 0;
  }
}

.page-why .why .cont .title1 {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 1024px) {
  .page-why .why .cont .title1 {
    padding-right: 1.4rem;
  }
}

@media (max-width: 767px) {
  .page-why .why .cont .title1 {
    padding-right: 1rem;
  }
}

.page-why .why .cont .title1 span:first-child {
  left: auto;
  right: -1.4rem;
}

@media (max-width: 1024px) {
  .page-why .why .cont .title1 span:first-child {
    right: 0;
  }
}
