@import "../../assets/styles/utils";

.ny-banner{
  position: relative;
  overflow: hidden;
  @include res(height,6rem);
  @include res(margin-bottom,1.15rem,(xs:.8rem));
  .img{
    position: relative;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.3);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  .banner-t{
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    @include res(bottom,.25rem);
    h1{
      color: #fff;
      text-transform: uppercase;
      @include res(margin-bottom, .7rem);
    }
  }
}

.crumbs{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(255,255,255,.8);
  text-transform: uppercase;
  flex-wrap: wrap;
  line-height: 1.2;
  transition: color .3s;
  @include res(font-size, .15rem,(md:.2rem,sm:.24rem,xs:.2rem));
  &.black{
    color: rgba(0,0,0,.8);
    a{
      &:hover{
        color: #000;
      }
    }
  }
  .iconfont{
    @include res(font-size, .16rem,(md:.18rem,sm:.3rem,xs:.2rem));
  }
  a{
    transition: all .3s;
    &:hover{
      color: #fff;
    }
    &:last-child{
      font-family: 'Poppins-Bold';
    }
  }
}