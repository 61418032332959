@font-face {
  font-family: "iconfont"; /* Project id 4295350 */
  src: url('iconfont.woff2?t=1700534726710') format('woff2'),
       url('iconfont.woff?t=1700534726710') format('woff'),
       url('iconfont.ttf?t=1700534726710') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sousuo:before {
  content: "\e64f";
}

.icon-zhankai:before {
  content: "\e6f5";
}

.icon-24gf-playCircle:before {
  content: "\ea82";
}

.icon-xiazai:before {
  content: "\e61b";
}

.icon-view:before {
  content: "\e847";
}

.icon-guanbi:before {
  content: "\eaf2";
}

.icon-wangzhanyunying:before {
  content: "\e624";
}

.icon-youxiang:before {
  content: "\e908";
}

.icon-24gl-telephone3:before {
  content: "\e953";
}

.icon-ditu:before {
  content: "\e629";
}

.icon-fanhuidingbu1:before {
  content: "\e623";
}

.icon-24gl-repost:before {
  content: "\e99b";
}

.icon-zengjia:before {
  content: "\e6ea";
}

.icon-31zhuanfa:before {
  content: "\e603";
}

.icon-facebook:before {
  content: "\e6e5";
}

.icon-fanhuidingbu:before {
  content: "\e651";
}

.icon-tuite:before {
  content: "\ee49";
}

.icon-jiantou_you:before {
  content: "\e602";
}

.icon-jiantou_zuo:before {
  content: "\ee4a";
}

.icon-jiantou_xiangyou:before {
  content: "\e600";
}

.icon-jiantou_xia:before {
  content: "\e601";
}

